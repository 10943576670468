@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: Poppins, sans-serif;
}

body {
   background-color: #eee;
}

/* Global Scrollbar Customization */
*::-webkit-scrollbar {
   width: 8px; 
   height: 8px; 
}

*::-webkit-scrollbar-thumb {
   background-color: rgba(0, 0, 0, 0.3);
   border-radius: 10px;
}

*::-webkit-scrollbar-track {
   background-color: transparent;
}

/* Firefox scrollbar styles */
* {
   scrollbar-width: thin; 
   scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
